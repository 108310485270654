import api from '@/api'

export default {
  namespaced: true,
  state: {},
  getters: {
    pointTypes() {
      return [
        "SECTION", "SUBSECTION", "DEFENDANTS", "KEY_WORDS", "AMOUNT"
      ]
    }
  },
  mutations: {},
  actions: {
    getSuitPointConfig(actions) {
      return api.base()
        .get('/config/suit-point/get/all')
        .then(
          response => (response.data.data),
          err => ({ error: err.data }),
        )
    },
    saveSuitPointConfig(actions, data) {
      return api.base()
        .post('/config/suit-point/update', data)
        .then(
          response => (response.data.data),
          err => ({ error: err.data }),
        )
    }
  }
}
